var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailForm passwordChangeArea" },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-label-text", {
                      staticStyle: { "padding-bottom": "25px !important" },
                      attrs: {
                        title: "LBL0003049",
                        value: _vm.portalInfo.loginId,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        label: "LBL0003050",
                        name: "portalId",
                        type: "text",
                      },
                      model: {
                        value: _vm.portalInfo.portalId,
                        callback: function ($$v) {
                          _vm.$set(_vm.portalInfo, "portalId", $$v)
                        },
                        expression: "portalInfo.portalId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-12",
                    staticStyle: { "text-align": "right" },
                  },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBL0003051", icon: "save" },
                          on: { btnClicked: _vm.saveId },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }