<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm passwordChangeArea">
        <template slot="card-detail">
          <div class="col-12">
            <!-- 기존 아이디 -->
            <c-label-text 
              title="LBL0003049" 
              style="padding-bottom: 25px !important;"
              :value="portalInfo.loginId" />
          </div>
          <div class="col-12">
            <!-- 변경할 아이디 - (영문+숫자 조합 5자리 이상) -->
            <c-text
              :required="true"
              label="LBL0003050"
              name="portalId"
              type="text"
              v-model="portalInfo.portalId">
            </c-text>
          </div>
          <div class="col-12" style="text-align:right">
            <q-btn-group outline >
              <!-- 아이디 변경 -->
              <c-btn label="LBL0003051" icon="save" @btnClicked="saveId" />
            </q-btn-group>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>
<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'initChangePw',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          vendorCd: '',
          loginId: '',
        }
      },
    },
  },
  data() {
    return {
      portalInfo: {
        vendorCd: '',
        loginId: '',
        portalId: '',
        chgUserId: '',
      },
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.saveUrl = transactionConfig.mdm.cim.vendor.chgId.url;
      this.portalInfo.vendorCd = this.popupParam.vendorCd;
      this.portalInfo.loginId = this.popupParam.loginId;
      this.portalInfo.chgUserId = this.$store.getters.user.userId;
    },

    saveId() {
      let idReg = {
        lowerCase: /[a-z]/g,
        upperCase: /[A-Z]/g,
        numberCase: /[0-9]/g,
        lowerCaseTest: false,
        upperCaseTest: false,
        numberCaseTest: false,
      };    

      if (this.portalInfo.portalId === '') {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000955', //변경할 아이디를 입력하세요,
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        this.$set(idReg, 'lowerCaseTest', idReg.lowerCase.test(this.portalInfo.portalId));
        this.$set(idReg, 'upperCaseTest', idReg.upperCase.test(this.portalInfo.portalId));
        this.$set(idReg, 'numberCaseTest', idReg.numberCase.test(this.portalInfo.portalId));
        let resultTest = this.$_.filter(idReg, (value) => {
          return value === false
        })
        if (resultTest.length > 1 || this.portalInfo.portalId.length < 5) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 'MSG0000956', // 아이디는 최소 5자리여야 하며,\r\n 영문/숫자를 포함해야합니다.
            type: 'warning', // success / info / warning / error
          });
          return;
        } else {
          this.$http.url = this.saveUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.portalInfo;
          this.$http.request((_result) => {
            if (_result.data == 'DUPLICATE') {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message: 'MSG0000957', // 사용중인 아이디가 있습니다.
                type: 'warning', // success / info / warning / error
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message: 'MSG0000958', // 접속 아이디가 변경되었습니다.
                type: 'success', // success / info / warning / error
              });
              this.$emit('closePopup', {portalId: _result});
            }
          },);
        }
      }
    },
  }
};
</script>
<style lang="sass">
.passwordChangeArea
  .titleArea
    width: 100%
    .title
      font-size:2em
      font-weight: 700
      text-align: center
      .emphasis
        color: #C10015
    .subtitle
      font-size:1.05em
      font-weight: 500
</style>